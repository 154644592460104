<template>
  <div>
    <hrm-player :controls="true" ref="myPlayer" @init="onInit" :source="src" :type="type" />
    <!-- <div>{{src}}</div> -->
  </div>
</template>

<style lang="scss">
body {
  margin: 0;
}
</style>
<script>
import hrmPlayer from '@/components/hrmPlayer';
import Http from '@/utils/http';
export default {
  name: 'App',
  components: {
    hrmPlayer,
  },
  computed: {
    baseApi() {
      return `${this.apihost}/develop`;
    },
  },
  data() {
    return {
      id: null,
      apihost: '',
      isLive: false,
      type: 'application/x-mpegURL',
      src: '',
    };
  },
  mounted: function () {    
    this.src = this.$route.query.url    
    this.apihost = this.$route.query.apihost
    this.id = this.$route.query.id    
    this.pointVideoId = this.$route.query.pointVideoId || null   
    this.isLive = this.$route.query.islive === 'true'
    this.loadVideo()
  },
  methods: {
    loadVideo() {
      Http.get(`${this.baseApi}/recording-order/${this.id}/pano`)
        .then((res) => {
          if(this.isLive) {
            this.src = res.data.live_url.replace('http://','https://')
          } else if(this.pointVideoId) {
            const _this = this
            let pointVideo = res.data.highlight.find(function (item) {
              console.log(item.id);
                return item.id == _this.pointVideoId;
            }, res.data.highlight)
            console.log(pointVideo);

            this.src = pointVideo.resource_video.full_url;
            let fileExtension = this.src.split('.').pop().toLowerCase();
            this.type = fileExtension === 'm3u8' ? 'application/x-mpegURL': 'video/mp4'
            console.log(this.type)
          }
          else {
            this.src =res.data.full[0].video
            let fileExtension = this.src.split('.').pop().toLowerCase();
            this.type = fileExtension === 'm3u8' ?  'application/x-mpegURL' :'video/mp4'
          } 
        this.$refs.myPlayer.initPlayer(res.data.panoconfig);
        })
    },
    onInit() {
      console.log('init')
    }
  },
};
</script>
