<template>
  <div class="viewer" v-bind:class="{ fullscreen: isFullscreen }">


      <div v-if="!isFullscreen" class="ctrl-btn" @click="setFullScreen">
        <img src="../../img/fullscreen-expand.png" alt="">
      </div>
      
      <div v-if="isFullscreen" class="ctrl-btn" @click="exitFullScreen">
        <img src="../../img/fullscreen-shrink.png" alt="">
      </div>
    <video
      id="video"
      ref="player"
      class="video-js vjs-16-9 vjs-default-skin vjs-big-play-centered vjs-controls-enabled"
      v-bind="$attrs"   
    >
    </video>
  </div>
</template>

<script>
import _videojs from "video.js";
const videojs = window.videojs || _videojs;
import view360 from "../../lib/view360.pkgd.min.js";

// import PanoControls from "../../lib/PanoControls.js";

import "videojs-flash";
import "video.js/dist/video-js.min.css";
import video_zhCN from "video.js/dist/lang/zh-CN.json";
videojs.addLanguage("zh-CN", video_zhCN);
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 组件名称 from '组件路径';
export default {
  name: "hrm-player",
  inheritAttrs: false,
  //引入组件
  props: {
    source: String,
    type: {
      type: String,
      default: "application/x-mpegURL",
    },
    width: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 600,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  //引入混淆
  mixins: [],
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      myPlayer: null,
      panoViewer: null,
      panoConfig: null,
      defaultOptions: {
        // muted: true,
        // autoplay: true,
        playsinline: true,
        language: "zh-CN",
        crossorigin: "anonymous",
        notSupportedMessage: "视频暂时无法播放",
        controlBar: {
          fullscreenToggle: true
        }
      },
      isFullscreen: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    source(val) {
      if (val) {
        // console.log('changesource')
        // this.changeSource(val, this.type);
      }
    },
  },
  //方法集合
  methods: {
    // 事件绑定
    bindEvent() {
      if (this.myPlayer) {
        for (let listener in this.$listeners) {
          this.myPlayer.on(listener, this.$listeners[listener]);
        }
      }
    },
    // 初始化播放器方法
    initPlayer(config) {
      let _this = this;
      console.log(_this.defaultOptions)
      this.myPlayer = videojs(this.$refs.player, this.defaultOptions, function () {
        _this.bindEvent();
        if (_this.source) {
          _this.changeSource(_this.source, _this.type);
        }

        var view360Container = document.createElement("div");
        view360Container.className = "container";
        var videoContainer = document.querySelector("#video");
        // var video = _this.$refs.player;
        
        var video = document.querySelector(".viewer video");
        videoContainer.insertBefore(view360Container, videoContainer.firstChild);
        console.log(config)
        var panoViewer =  new view360.PanoViewer(view360Container, {
          video: video,
          gyroMode: 'none',
          projectionType: "hikvision",
          pitch: config.pitch,
          fov: config.fov,
          pitchRange: [config.pitch_min, config.pitch_max],
          yawRange: [config.yaw_min, config.yaw_max],
          fovRange: [config.fov_min, config.fov_max],
          hikConfig: {
            fovH: config.fovh,
            fovV: config.fovv,
            vOffset:config.voffset
          }
        });
        _this.panoViewer = panoViewer;
        _this.panoConfig = config;
        window.addEventListener("resize", function () {
          panoViewer.updateViewportDimensions();
        });
        _this.$emit("init", this);
        
        // var boxviewer = document.querySelector(".viewer");
        // PanoControls.init(boxviewer, panoViewer);
        // PanoControls.showLoading();
      });
      this.myPlayer.on('fullscreenchange',function() {
        // console.log('hhh')
        // this.exitFullscreen();
        return false;
      })
      // this.myPlayer.setCrossOrigin('anonymous')
    },
    // 播放器赋值地址
    changeSource(source, type) {
      if (this.myPlayer) {
        // this.myPlayer.reset();
        this.myPlayer.src({
          src: source,
          type: type, // 这个type值必写
        });

        // this.myPlayer.play();
      }
    },
    // 播放
    play() {
      if (this.myPlayer) {
        videojs.log("play");
        this.myPlayer.play();
      }
    },
    // 暂停
    pause() {
      if (this.myPlayer) {
        this.myPlayer.pause();
      }
    },
    // 获取播放时间
    currentTime() {
      if (this.myPlayer) {
        return this.myPlayer.currentTime();
      }
    },
    // 全屏
    setFullScreen() {
      // if (this.myPlayer) {
      //   this.myPlayer.requestFullscreen();
      // }
      this.isFullscreen = true;
      // this.changeOrientation(document.querySelector(".viewer"))
      console.log(this.panoViewer);
      if(this.panoViewer) {

        this.panoViewer.enterFullScreen();
        // this.panoViewer.lookAt({yaw: this.panoConfig.pitch, pitch: this.panoConfig.yaw, fov: this.panoConfig.fov}, 100);
        
        var ratio = document.documentElement.clientWidth / document.documentElement.clientHeight;
        let diff = (0.56-ratio) * 430;
        this.panoViewer.setYawRange([-this.panoConfig.pitch_max-diff, -this.panoConfig.pitch_min+diff]);
        this.panoViewer.setPitchRange([this.panoConfig.yaw_min, this.panoConfig.yaw_max]);
        console.log([-this.panoConfig.pitch_max, -this.panoConfig.pitch_min])
      }
    },
    exitFullScreen() {
      this.isFullscreen = false;
      
      this.panoViewer.setYawRange([this.panoConfig.yaw_min, this.panoConfig.yaw_max]);
      this.panoViewer.setPitchRange([this.panoConfig.pitch_min, this.panoConfig.pitch_max]);
      this.panoViewer.exitFullScreen();
    },
    // 重置
    reset() {
      if (this.myPlayer) {
        this.myPlayer.reset();
        this.myPlayer.hasStarted(false);
      }
    },
    // 销毁播放器
    dispose() {
      if (this.myPlayer) {
        this.myPlayer.dispose();
        this.myPlayer = null;
      }
    },
    
  changeOrientation($print) {
    var width = document.documentElement.clientWidth;
    var height =  document.documentElement.clientHeight;
    if(width < height) {
      $print.style.width = height + 'px';
      $print.style.height = width + 'px';
      $print.style.top =  (height - width) / 2  + 'px';
      $print.style.left =  0 - (height - width) / 2  + 'px';
      $print.style.transform = 'rotate(90deg)';
      $print.style.transformOrigin = '50% 50%';
    }

    var evt = "onorientationchange" in window ? "orientationchange" : "resize";

        window.addEventListener(evt, function() {

      setTimeout(function() {
          var width = document.documentElement.clientWidth;
          var height =  document.documentElement.clientHeight;

      if( width > height ){
        $print.style.width = width + 'px';
        $print.style.height = height + 'px';
        $print.style.top =  0;
        $print.style.left =  0 ;
        $print.style.transform = 'none';
        $print.style.transformOrigin = '50% 50%';
      }
      else {
        $print.style.width = height + 'px';
        $print.style.height = width + 'px';
        $print.style.top =  (height - width) / 2  + 'px';
        $print.style.left =  0 - (height - width) / 2  + 'px';
        $print.style.transform = 'rotate(90deg)';
        $print.style.transformOrigin = '50% 50%';
      }
    }, 300);
    }, false);
  }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.initPlayer();
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {
    this.dispose();
  },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
};
</script>

<style lang="scss">
.video-js .vjs-big-play-button {
  // display: none;
  left: 50%;
  top: 50%;
  margin-left: -1.5em;
  margin-top: -0.8em;
}

.viewer {
  position: relative;
  width: 100%;
  /* height: 400px; */
  // max-width: 800px;
  margin: 0px auto;
  overflow: hidden;
  outline: none;
}

.fullscreen-container .viewer {
  max-width: none;
  height: 100%;
}

// .viewer:after {
//   position: relative;
//   display: block;
//   content: "";
//   padding-top: 60%;
//   width: 100%;
//   z-index: -1;
// }

.viewer .container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.viewer canvas {
  width: 100%;
  height: 100%;
}

.viewer video {
  position: absolute;
  visibility: hidden;
  display: none;
}

.fullscreen-container {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
}
.enterfs {
  display: block !important;
}
.fullscreen-container .enterfs {
	display: none !important;
}
.exitfs {
	display: none !important;
}
.fullscreen-container .exitfs {
	display: block !important;
}
.entervr {
  display: block !important;
}

.panoviewer-control button {
  background-color: rgba(0,0,0,.6);
  color: white;
  border-radius: 5px;
  border: none;
  margin: 5px;
  padding: 5px;
  outline: none;
  font-size: 0px;
  margin-bottom: 5px;
}
.panoviewer-control button.enterfs,
.panoviewer-control button.exitfs {
  margin-bottom: 10px;
}
.panoviewer-control button svg {
  width: 30px;
  height: 30px;
}

.panoviewer-control {
  text-align: center;
  position: absolute;
  font-weight: 300;
  top: 0px;
  right: 5px;
  z-index: 1;
}
.panoviewer-control .camera {
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: rgba(0,0,0,.6);
    border-radius: 100px;
}
.panoviewer-control .pie,.camera {
    height: 40px;
    width: 40px;
}

.image360_loading,
.image360_loading:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.image360_loading {
  display: none;
  width: 92px;
  height: 92px;
  z-index: 1;
}

.image360_loading .image360_loadingBar {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  width: 32px;
  height: 8px;
  margin: auto;
  background-image: url(../../img/common_loading_mo_white.gif);
  background-repeat: no-repeat;
  background-size: 32px 8px
}

.image360_loading:before {
  background-image: url(../../img/sp_component.png);
  background-repeat: no-repeat;
  -webkit-background-size: 391px 349px;
  background-size: 391px 349px;
  display: inline-block;
  width: 92px;
  height: 92px;
  background-position: 0 -85px
}

.image360_loading.is-loading {
  display: block;
}


/**
 *
 */
 .gyroTouchOptions {
  position: absolute;
  right: 0px;
  bottom: 0px;
  text-align: right;
}

.optionSet {
  /* position: absolute;
  bottom:0px;
  right: 0px; */
}

.optionSet .option {
  border: 1px;
  border-radius: 3px;
  background-color: rgb(188, 188, 207);
  color: white;
  padding: 2px;
  height: 34px;
  box-sizing: border-box;
  font-size: 0;
  margin: 5px 0px;
  display: inline-block;
  vertical-align: middle;
}

.optionSet .option:hover {
  background-color: rgb(73, 73, 201);
}

.optionSet .option.selected {
  background-color: rgb(175, 64, 226);
}

.selectGroup {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.selectGroup .option {
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  line-height: 34px;
  padding: 0px 10px;
  min-width: 34px;
  text-align: center;
}

.statusButton.option {
  position: relative;
  background-color:#7fbeff;
  margin: 5px;
}
.selectGroup .option span {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  background: url(../../img/arrow.svg) no-repeat;
  background-size: 100% 100%;
}
.selectGroup .option span.vertical {
  transform: rotate(90deg);
}
.statusButton.option svg {
  width: 40px;
  fill: #fff;
  stroke: #fff;
}

.statusButton.option:hover{
  background-color: #7f1e1f;
}

.selectGroup.hide {
  display: none;
}


@media screen and (max-width: 500px) {
  .panoviewer-control {
    right: 0px;
    top: 0px;
  }
  .panoviewer-control button {
    margin: 5px 5px 5px 0px;
    padding: 5px;
    float:right;
  }
  .panoviewer-control button svg {
    width: 20px;
    height: 20px;
  }
  .panoviewer-control .camera {
    transform: scale(0.7);
  }
}

  .ctrl-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 38px;
    height: 38px;
    background-color: rgba(43,51,63,.7);
    border-radius: 2px;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
    }
  }
.viewer.fullscreen {
  .video-js.vjs-16-9 {
    // height: 100%;
    padding-top: 100vw;
    // display: none;
    z-index: -1;
  }
  width: 100vh;
  height: 100vw;
  transform: translate3d(100vw,0,0) rotate(90deg);
  transform-origin: top left;
}

.video-js .container,
.video-js .container canvas {
  z-index: -100;
}
.video-js {
    z-index: 1;
}
</style>
